import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import Slider from "react-slick"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./ValuationModule.scss"
import GGFXImage from "../../modules/GGFXImage"
const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ValuationModule = ({ valuationData, PageData,isPreviewEnabled,isPageTrue,isAreaGuideDetails }) => {
  const {
    cta_item: { title, link },
    add_image,
    content,
  } = valuationData

  const imageList = add_image?.length > 0 ? add_image : ""

  let imageCards1 = imageList.slice(0, 5)
  let imageCards2 = imageList.slice(5, 10)
  let imageCards3 = imageList.slice(10)

  const { isLargeScreen } = useDeviceMedia()

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
  }
  const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 2.9,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
  }
  const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 2.4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
  }

  // let processedImages = JSON.stringify({})
  // if (PageData?.imagetransforms) {
  //   processedImages = PageData?.imagetransforms?.valuation_image_Transforms
  // }
  // console.log(PageData)
  // const imagename = "page.valuation_image.images"
  let imagename = ""
  imagename = isAreaGuideDetails? "area-guide.valuation_image.images": "page.valuation_image.images"
  return (
    <div className={`valuation-module-wrapper ${isPageTrue?"mort-rental-blocks":""}`}>
      <Container>
        <div className="valuation-sections">
          <div className="valuation-content-sections">
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={100}
              offset={100}
            >
              <h2>
                {valuationData?.title} <span className="new">New</span>
              </h2>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={150}
              offset={100}
            >
              {(valuationData?.content?.data?.content || (valuationData?.content&&isPreviewEnabled))&&
              <div className="description">
                <ContentModule
                  Content={valuationData?.content?.data?.content || valuationData?.content}
                />
              </div>
            }
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__slideInUp"
              animateOnce
              delay={200}
              offset={100}
            >
              <PreviewCTALink
                class="button button-filled-green"
                link={link}
                title={title}
              ></PreviewCTALink>
            </ScrollAnimation>
          </div>
          {!isLargeScreen && (
            <div className="image-wrapper-sections">
              <Slider {...settings}>
                {imageCards1?.map((list, index) => {
                  return (
                    <div className="image-list" key={list?.id}>
                      <GGFXImage
                        ImageSrc={list?.images}
                        altText={"Valuation slider"}
                        imagetransforms={PageData?.ggfx_results}
                        renderer="srcSet"
                        imagename={imagename}
                        strapiID={PageData?.strapi_id}
                      />
                    </div>
                  )
                })}
              </Slider>

              <Slider {...settings1}>
                {imageCards2?.map((list, index) => {
                  return (
                    <div className="image-list" key={list?.id}>
                       <GGFXImage
                        ImageSrc={list?.images}
                        altText={"Valuation slider"}
                        imagetransforms={PageData?.ggfx_results}
                        renderer="srcSet"
                        imagename={imagename}
                        strapiID={PageData?.strapi_id}
                      />
                    </div>
                  )
                })}
              </Slider>
              <Slider {...settings2}>
                {imageCards3?.map((list, index) => {
                  return (
                    <div className="image-list" key={list?.id}>
                       <GGFXImage
                        ImageSrc={list?.images}
                        altText={"Valuation slider"}
                        imagetransforms={PageData?.ggfx_results}
                        renderer="srcSet"
                        imagename={imagename}
                        strapiID={PageData?.strapi_id}
                      />
                    </div>
                  )
                })}
              </Slider>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default ValuationModule
