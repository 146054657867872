import React, { useState } from "react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { Container } from "react-bootstrap"
import { useEffect } from "react"
import "./FeatureComponent.scss"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { Link } from "gatsby"
import ScrollAnimation from 'react-animate-on-scroll';


const FeatureComponent = ({ item }) => {
  const { title, search_item } = item
  const { isMobile } = useDeviceMedia()
  const [key, setKey] = useState("for-sale")

  const [listItem, setListItem] = useState(search_item)
  const [showMore, setShowMore] = useState(true)

  useEffect(() => {
    return setListItem(isMobile ? search_item.slice(0, 8) : search_item)
  }, [isMobile])

  const handleMore = () => {
    setListItem(search_item)
    setShowMore(false)
  }

  const handleLess = () => {
    setListItem(search_item.slice(0, 8))
    setShowMore(true)
  }

  return (
    <div className="feature-component-wrapper section-pad-bottom">
    
      <Container>
        <div className="feature-module">
        <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
          <h2>{title}</h2>
        </ScrollAnimation>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={k => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="for-sale" title="For Sale">
              {listItem?.length > 0 && (
                <div className="feature-list">
                  {listItem.map((item, index) => {

                    return (
                      <ScrollAnimation key={item?.title} animateIn="animate__slideInUp" animateOnce delay={index*100} offset={100}>
                      <Link to={`/properties/for-sale/${item?.link_path}`}>
                        <div key={index} className="feature-item">
                          <img src={item?.image?.url} alt={`Sale Image for ${item.title?.toLowerCase()}`} />
                          <div className="content-section">
                            <div className="feature-name">{item?.title}</div>
                          </div>
                        </div>
                      </Link>
                      </ScrollAnimation>
                      
                    )
                  })}
                  {isMobile && search_item?.length > 8 && (
                    <>
                      {showMore && (
                        <p onClick={handleMore} className="more-feature">
                          View more
                        </p>
                      )}
                      {!showMore && (
                        <p onClick={handleLess} className="more-feature">
                          View less
                        </p>
                      )}
                    </>
                  )}
                </div>
              )}
            </Tab>
            <Tab eventKey="for-rent" title="For Rent">
              {listItem?.length > 0 && (
                <div className="feature-list">
                  {listItem.map((item, index) => {
                    return (
                      <Link key={item?.title} to={`/properties/to-rent/${item?.link_path}`}>
                        <div key={index} className="feature-item">
                          <img src={item?.image?.url} alt={` Rent Image for ${item.title?.toLowerCase()}`} />
                          <div className="content-section">
                            <div className="feature-name">{item?.title}</div>
                          </div>
                        </div>
                      </Link>
                    )
                  })}
                  {isMobile && search_item?.length > 8 && (
                    <>
                      {showMore && (
                        <p onClick={handleMore} className="more-feature">
                          View more
                        </p>
                      )}
                      {!showMore && (
                        <p onClick={handleLess} className="more-feature">
                          View less
                        </p>
                      )}
                    </>
                  )}
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </Container>
      
    </div>
  )
}

export default FeatureComponent
