import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import SearchBox from "./../SearchBox/SearchBox"
import "./assets/styles/_index.scss"
import GGFXImage from "../../modules/GGFXImage"
import HomeBannerImage from "./HomeBannerImage"
const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const HomeBanner = props => {
  const ImageRenderList = ({ item, imagename,ImageSrc }) => {
   
    return (
        
        <GGFXImage
        ImageSrc={ImageSrc}
        altText={item.banner_title}
        imagetransforms={item?.ggfx_results}
        renderer="bgImg"
        imagename={imagename}
        strapiID={item?.strapi_id}
        className="banner-img d-none d-md-block"
        lazyLoading="eager"   
    />
    )
}


  return (
    <section
      className={`banner ${props.tag === "landing" ? "inner-page" : ""} ${
        props?.show_search ? " home-search" : ""
      }`}
    >
      {
     props?.mobileImage?.url?
      <ImageRenderList className="banner-img d-block d-md-none" ImageSrc={props?.mobileImage} item={props?.pageData} imagename={"page.mobile_image.banner_mobile_image"} />
      :
      // <HomeBannerImage mobileImg = {props?.mobileImage?.url} />
      props?.image?.url && 
        <ImageRenderList ImageSrc={props?.image} item={props?.pageData} imagename={"page.banner_section_banner_image.landing_banner_image"} />
      }
      

      <div className="overlay-bg"></div>
      <Container className="banner-search-container">
        <Row>
          <Col lg={12}>
            
            {props?.banner_title && <h1>{props.banner_title}</h1>}

            {(props.banner_content?.data?.banner_content||(props?.banner_content&&props.isPreviewEnabled)) && (
              <ContentModule
                Content={props.banner_content?.data?.banner_content || props.banner_content}
              />
            )}

            <ul className="list-inline">
              {(props?.cta_1_title && props?.cta_1_link) ||
                (props?.cta_1_custom_link && (
                  <li className="list-inline-item">
                    <PreviewCTALink
                      class="button button-filled-green"
                      link={
                        props.cta_1_link
                          ? props.cta_1_link
                          : { external_link: props?.cta_1_custom_link }
                      }
                      title={props?.cta_1_title}
                      target_window={props.cta_1_link?.target_window}
                    />
                  </li>
                ))}
              
            </ul>
          </Col>
        </Row>
      </Container>
      {props?.show_search && <SearchBox />}
    </section>
  )
}

export default HomeBanner
